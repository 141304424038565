<template>
  <div class="d-flex justify-start align-center event-Item_textBox_time">
    <div v-if="filename">
      <v-img
        contain
        class="me-2"
        width="28px"
        height="28px"
        aspect-ratio="1"
        :src="src"
      ></v-img>
    </div>
    <span v-html="text" :class="textClass">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    filename: String,
    textClass: String
  },
  computed: {
    src() {
      return require(`@/assets/img/icon/${this.filename}`)
    },
  },
};
</script>